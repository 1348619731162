<template>
  <div class="py-5 px-4">
    <h3 class="flex items-center gap-x-3 py-5">
      <img src="/svg/user-plus.svg" />
      <span class="font-EffraR text-blackedsidebar">Nouveau patient</span>
    </h3>
    <form v-on:submit.prevent="onSave">
      <ValidationObserver ref="observer">
        <div class="grid gap-y-5 xl:gap-x-5 grid-cols-1 xl:grid-cols-2 mb-5">
          <div>
            <label for="prenom" class="font-EffraR">Prénom</label>
            <ValidationProvider name="Prénom" rules="required" id="prenom">
              <div slot-scope="{ errors }">
                <input
                  v-model="data.firstName"
                  type="text"
                  class="
                    px-3
                    text-gray-800
                    transition-colors
                    duration-150
                    bg-white
                    border-2 border-solid
                    rounded-cu
                    w-full
                    focus:outline-none
                    focus:border-dokBlue-ultra
                    h-12
                    flex
                    items-center
                    justify-between
                    cursor-pointer
                  "
                  :class="errors[0] ? 'border-red-600' : 'border-grayaaa'"
                  placeholder="Prénom"
                />
              </div>
            </ValidationProvider>
          </div>
          <div>
            <label for="nom" class="font-EffraR">Nom</label>
            <ValidationProvider name="Nom" rules="required" id="nom">
              <div slot-scope="{ errors }">
                <input
                  v-model="data.lastName"
                  type="text"
                  class="
                    px-3
                    text-gray-800
                    transition-colors
                    duration-150
                    bg-white
                    border-2 border-solid
                    rounded-cu
                    w-full
                    focus:outline-none
                    focus:border-dokBlue-ultra
                    h-12
                    flex
                    items-center
                    justify-between
                    cursor-pointer
                  "
                  :class="errors[0] ? 'border-red-600' : 'border-grayaaa'"
                  placeholder="Nom"
                />
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="grid gap-y-5 grid-cols-1 mb-5">
          <birth-day
            :date-save="data.birthday"
            :on-set-date="setBirthDay"
            custom-class="appearance-none px-3 text-gray-800 transition-colors duration-150 bg-white border-2 border-solid border-grayaaa rounded-cu w-full focus:outline-none focus:border-dokBlue-ultra h-12 cursor-pointer"
          ></birth-day>
        </div>
        <div class="grid gap-y-5 grid-cols-1 mb-5">
          <div>
            <label for="CIN" class="font-EffraR">CIN</label>
            <ValidationProvider name="CIN" rules="" id="CIN">
              <div slot-scope="{ errors }">
                <input
                  v-model="data.cin"
                  type="text"
                  class="
                    px-3
                    text-gray-800
                    transition-colors
                    duration-150
                    bg-white
                    border-2 border-solid
                    rounded-cu
                    w-full
                    focus:outline-none
                    focus:border-dokBlue-ultra
                    h-12
                    flex
                    items-center
                    justify-between
                    cursor-pointer
                  "
                  :class="errors[0] ? 'border-red-600' : 'border-grayaaa'"
                  placeholder="Numéro de la carte d'identité "
                />
              </div>
            </ValidationProvider>
          </div>
          <div class="flex flex-col">
            <label for="phone" class="font-EffraR">Téléphone</label>
            <ValidationProvider name="mobile" rules="required" id="mobile">
              <div slot-scope="{ errors }">
                <input
                  v-model="data.mobile"
                  type="text"
                  class="
                    px-3
                    text-gray-800
                    transition-colors
                    duration-150
                    bg-white
                    border-2 border-solid
                    rounded-cu
                    w-full
                    focus:outline-none
                    focus:border-dokBlue-ultra
                    h-12
                    flex
                    items-center
                    justify-between
                    cursor-pointer
                  "
                  :class="
                    phoneError || errors[0]
                      ? 'border-red-600'
                      : 'border-grayaaa'
                  "
                  placeholder="ex: +212 6 67 39 05 34"
                />
              </div>
            </ValidationProvider>
            <span
              class="text-red-600 font-EffraM text-sm"
              v-if="phoneNotCurrect"
              >Le numéro de téléphone est incorrect</span
            >
          </div>
          <div>
            <label for="email" class="font-EffraR">Adresse mail</label>
            <ValidationProvider name="Email" rules="email" id="email">
              <div slot-scope="{ errors }">
                <input
                  v-model="data.email"
                  type="email"
                  class="
                    px-3
                    text-gray-800
                    transition-colors
                    duration-150
                    bg-white
                    border-2 border-solid
                    rounded-cu
                    w-full
                    focus:outline-none
                    focus:border-dokBlue-ultra
                    h-12
                    flex
                    items-center
                    justify-between
                    cursor-pointer
                  "
                  :class="errors[0] ? 'border-red-600' : 'border-grayaaa'"
                  placeholder="Adresse mail"
                />
              </div>
            </ValidationProvider>
          </div>
        </div>
      </ValidationObserver>
      <div class="flex flex-col xl:flex-row">
        <button
          @click="onBack"
          type="button"
          class="
            text-base
            bg-blackdok
            py-2
            px-5
            text-white
            border-0
            rounded
            shadow
            cursor-pointer
            font-EffraR
            mr-2
          "
        >
          <i class="fas fa-chevron-left"></i>
        </button>
        <button
          @click="onSave"
          type="submit"
          class="
            h-12
            flex
            items-center
            justify-center
            gap-x-2
            bg-dokBlue-ultra
            rounded-cu
            border-0
            w-full
            cursor-pointer
          "
        >
          <img src="/svg/user-plus-white.svg" />
          <span class="text-lg font-EffraR text-white"
            >Créer le nouveau patient</span
          >
        </button>
      </div>
    </form>
  </div>
</template>

<script>
const birthDay = () => import("@/views/global-components/birthday");

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    onSingUp: {
      type: Function,
      required: true
    },
    setBirthDay: {
      type: Function,
      required: true
    },
    phoneError: {
      type: Boolean,
      required: true
    },
    phoneNotCurrect: {
      type: Boolean,
      required: true
    },
    onBack: {
      type: Function,
      required: false
    }
  },
  components: { birthDay },
  methods: {
    async onSave() {
      event.preventDefault();
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      this.onSingUp();
    }
  }
};
</script>

<style></style>
